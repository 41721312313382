<template>
  <b-table :fields="columns" :items="ivaEmpresaFeriadoInfo" :busy="isDataLoading" responsive>
    <template #table-busy>
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </template>
    <template #cell(actions)="row">
      <feather-icon
        icon="TrashIcon"
        class="cursor-pointer mr-1"
        size="20"
        @click="deleteProduct(row.item)"
      />
    </template>
  </b-table>
</template>
<script>
import { BSpinner, BTable } from "bootstrap-vue";
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    BSpinner,
    BTable,
  },
  async created() {
    this.isDataLoading = true;
    await this.getIvaEmpresaFeriado();
    this.isDataLoading = false;
  },
  computed: {
    ...mapGetters({
      ivaEmpresaFeriadoInfo: 'ivaFeriado/ivaEmpresaFeriadoState'
    })
  },
  methods: {
    ...mapActions({
      getIvaEmpresaFeriado: 'ivaFeriado/getIvaEmpresaFeriadoAction',
    })
  },
  data() {
    return {
      isDataLoading: false,
      columns: [
        {
          key: "feriado",
          label: "Feriado",
        },
        {
          key: "empresa",
          label: "Empresa",
        },
      ]
    };
  },
};
</script>