var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', [_c('b-row', [_c('b-col', [_c('b-card', {
    attrs: {
      "title": "Configuración IVA Feriado"
    }
  }, [_c('b-card-body', {
    staticClass: "text-center"
  }, [_c('b-form', {
    staticClass: "row justify-content-center m-0 mb-2",
    attrs: {
      "inline": ""
    },
    on: {
      "submit": function submit($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.submitData($event);
      }
    }
  }, [_c('label', {
    staticClass: "mr-sm-2",
    attrs: {
      "for": "iva"
    }
  }, [_vm._v("IVA")]), _c('v-select', {
    staticClass: "col-12 col-md-4 mr-md-2",
    attrs: {
      "id": "iva",
      "uid": "ivaId",
      "options": _vm.ivaList,
      "reduce": function reduce(i) {
        return i.id;
      },
      "label": "descripcion"
    },
    model: {
      value: _vm.form.ivaId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "ivaId", $$v);
      },
      expression: "form.ivaId"
    }
  }), _c('label', {
    staticClass: "mr-sm-2",
    attrs: {
      "for": "date"
    }
  }, [_vm._v("Fecha")]), _c('flat-pickr', {
    staticClass: "col-12 col-md-4 mr-md-2 form-control",
    attrs: {
      "id": "date",
      "config": _vm.config
    },
    model: {
      value: _vm.form.fechas,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fechas", $$v);
      },
      expression: "form.fechas"
    }
  }), _c('b-button', {
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v("Guardar")])], 1), _c('b-row', [_c('iva-feriado-table')], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }