<template>
  <b-container>
    <b-row>
      <b-col>
        <b-card title="Configuración IVA Feriado">
          <b-card-body class="text-center">
            <b-form
              @submit.stop.prevent="submitData"
              class="row justify-content-center m-0 mb-2"
              inline
            >
              <label class="mr-sm-2" for="iva">IVA</label>
              <v-select
                id="iva"
                uid="ivaId"
                class="col-12 col-md-4 mr-md-2"
                :options="ivaList"
                :reduce="(i) => i.id"
                v-model="form.ivaId"
                label="descripcion"
              />
              <label class="mr-sm-2" for="date">Fecha</label>
              <flat-pickr
                id="date"
                class="col-12 col-md-4 mr-md-2 form-control"
                v-model="form.fechas"
                :config="config"
              />
              <b-button type="submit" variant="primary">Guardar</b-button>
            </b-form>
            <b-row>
              <iva-feriado-table />
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import {
  BRow,
  BCol,
  BAlert,
  BOverlay,
  BCard,
  BCardBody,
  BCardTitle,
  BCardHeader,
  BContainer,
  BCardText,
  BButton,
  BForm,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import IvaFeriadoTable from "./IvaFeriadoTable";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BOverlay,
    BCard,
    BCardBody,
    BCardHeader,
    BContainer,
    BCardTitle,
    BCardText,
    BButton,
    BForm,
    BFormGroup,
    vSelect,
    flatPickr,
    IvaFeriadoTable,
    ToastificationContent,
  },
  data() {
    return {
      form: {
        ivaId: null,
        fechas: moment(new Date()).format("YYYY-MM-DD"),
      },
      config: {
        mode: "range",
        dateFormat: "Y-m-d",
      },
    };
  },
  async created() {
    await this.searchIva();
    if (!!this.ivaList) {
      this.form.ivaId = this.ivaList[0].id;
    }
  },
  computed: {
    ...mapGetters({
      ivaList: "ivaFeriado/ivaListState",
    }),
  },
  methods: {
    showNotificationOk() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Exito",
          icon: "CheckIcon",
          text: "Datos guardados",
          variant: "success",
        },
      });
    },
    showNotificationError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          text: "No se pudo completar el registro",
          icon: "AlertTriangleIcon",
          title: "Error",
          variant: "danger",
        },
      });
    },
    async submitData() {
      try {
        await this.saveData(this.form);
        this.showNotificationOk();
        await this.getIvaEmpresaFeriado();
      } catch (error) {
        this.showNotificationError();
      }
    },
    ...mapActions({
      saveData: "ivaFeriado/submitIvaFeriadoEmpresaAction",
      searchIva: "ivaFeriado/getIvaListAction",
      getIvaEmpresaFeriado: "ivaFeriado/getIvaEmpresaFeriadoAction",
    }),
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>