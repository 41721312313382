var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-table', {
    attrs: {
      "fields": _vm.columns,
      "items": _vm.ivaEmpresaFeriadoInfo,
      "busy": _vm.isDataLoading,
      "responsive": ""
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-center text-danger my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v("Loading...")])], 1)];
      },
      proxy: true
    }, {
      key: "cell(actions)",
      fn: function fn(row) {
        return [_c('feather-icon', {
          staticClass: "cursor-pointer mr-1",
          attrs: {
            "icon": "TrashIcon",
            "size": "20"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteProduct(row.item);
            }
          }
        })];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }